import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Note = makeShortcode("Note");
const ImageContainer = makeShortcode("ImageContainer");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Usually when a custom card "does not work", it's due to the fact that the user is expecting the wrong thing, there is no magic, the user `}<strong parentName="p">{`needs`}</strong>{` to use it correctly for the card to "work".`}</p>
    <p>{`Like integrations, most custom-cards will not be selectable in the card-chooser, this is a feature that the developer of that card needs to add (maybe hint that to the dev of your favourite card)`}</p>
    <Note mdxType="Note">
      <p>{`Here is an example:`}</p>
      <p><a parentName="p" {...{
          "href": "https://developers.home-assistant.io/docs/frontend/custom-ui/lovelace-custom-card#graphical-card-configuration"
        }}>{`https://developers.home-assistant.io/docs/frontend/custom-ui/lovelace-custom-card#graphical-card-configuration`}</a></p>
    </Note>
    <p>{`So since you can not select it you need to use the card at the bottom of that list "Manual"`}</p>
    <ImageContainer mdxType="ImageContainer">
  <img src="/images/outlet_cc_card_picker.png" />
    </ImageContainer>
    <Caption mdxType="Caption">
  <center>Manual card is used for custom cards</center>
    </Caption>
    <p><strong parentName="p">{`Now here it gets tricky!`}</strong></p>
    <p>{`Almost `}<strong parentName="p">{`all`}</strong>{` configuration examples for custom cards are like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`- type: 'type:some_card'
`}</code></pre>
    <p>{`That will `}<strong parentName="p">{`not`}</strong>{` work, the manual card is a list item by itself, so what you now told lovelace to use was really:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`- - type: 'type:some_card'
`}</code></pre>
    <p>{`And that will not work, and will give you:`}</p>
    <ImageContainer mdxType="ImageContainer">
  <img src="/images/outlet_cc_no_card_type.png" />
    </ImageContainer>
    <p>{`Remove "- " (both the dash, and the space) from in front of "type" and change the indents of the rest of that configuration so it matches.
(Maybe tip the developer of your favouritte card yo make the example workable with the "manual" card ?)`}</p>
    <p>{`To recap, bad:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`- type: 'type:some_card'
`}</code></pre>
    <p>{`Good:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`type: 'type:some_card'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      